@tailwind base;
@tailwind components;
@tailwind utilities;

/* montserrat-300 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff'), /* Modern Browsers */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.woff') format('woff'), /* Modern Browsers */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-300italic - vietnamese_latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/assets/fonts/montserrat/montserrat-v23-vietnamese_latin-ext_latin_cyrillic-ext_cyrillic-300italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}


@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap');


@layer components { 
    html { @apply overflow-y-auto }
    body { @apply bg-white }
    .app { @apply relative }
    /* scrollbar */

    
    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar { @apply w-2 h-2 }
    
    *::-webkit-scrollbar-track { @apply bg-zinc-100 }
    
    *::-webkit-scrollbar-thumb { @apply bg-zinc-400 }
    /* container */
    .container { @apply mx-auto pr-4 pl-4 }
    /* page loading */
    .loading { @apply absolute inset-0 w-full h-full bg-white z-[1001] grid place-items-center }
    .loader { @apply grid grid-cols-loader gap-4 }
    .loader-animation { @apply w-12 h-12 grid }
    .loader-animation > span { @apply rounded-full border-2 border-gray-500 animation-both animate-scaleRipple }
    .loader-text { @apply grid items-center text-2xl text-gray-500 h-12 pb-1 }    
    /* header */
    .header { @apply relative top-0 right-0 left-0 w-full h-[8rem] bg-no-repeat bg-[length:100%_180px] bg-white }
    .header .container { @apply grid w-full h-full place-items-center }
    .header .logo-brand { @apply relative w-[7rem] h-[7rem] }
    .header .logo { @apply grid w-full h-full }
    /* figure */
    .figure-hero { @apply relative inset-0 w-full h-full z-[1] rounded-sm overflow-hidden }
    .figure-hero::after { @apply absolute inset-0 w-full h-full z-[2] content-[''] clear-both bg-black/[0.2] }
    .picture { @apply grid object-cover overflow-hidden max-h-[768px] }
    .figcaption { @apply relative pt-8 pb-8 pr-4 pl-4 w-full opacity-100 z-20 mx-auto bg-white border-[2px] border-white lg:bg-white/[.9] text-zinc-700 }

    /* nav */
    .nav { @apply lg:border-b-[1px] }
    .nav ul { @apply relative grid gap-3 overflow-hidden z-20 pt-4 pb-4 }
    .nav ul li { @apply grid }
    .nav ul li a { @apply relative text-center grid pt-2 pb-2 pr-4 pl-4 font-medium w-full h-[3rem] items-center rounded-sm ease-in-out duration-300 delay-100 text-gray-900 hover:text-orange-600 font-montserrat }
    .nav ul li a.active { @apply text-orange-600 }
    .nav-trigger { @apply fixed right-4 bottom-4 w-12 h-12 hidden z-[601] bg-zinc-500 rounded-sm border-[2px] border-zinc-500 shadow-md place-items-center cursor-pointer outline-none ease-in-out duration-300 delay-100 }
    .nav-trigger > span { @apply relative w-8 h-[2px] bg-white flex ease-in-out duration-300 delay-100 }
    .nav-trigger > span::before,
    .nav-trigger > span::after { @apply absolute content-[''] clear-both w-8 h-[2px] bg-white ease-in-out duration-300 delay-100 }
    .nav-trigger > span::before { @apply -top-2 }
    .nav-trigger > span::after { @apply -bottom-2 }
    
    .nav-trigger.is-active { @apply shadow-none bg-white border-white }
    .nav-trigger.is-active > span { @apply bg-white }
    .nav-trigger.is-active > span::before { @apply top-0 -rotate-45 }
    .nav-trigger.is-active > span::after { @apply bottom-0 rotate-45 }
    .nav-trigger.is-active > span::before,
    .nav-trigger.is-active > span::after { @apply bg-zinc-600 }    
    .nav-overlay { @apply hidden }    
   
    
    /* modal */
    .modal { @apply fixed grid top-0 w-[calc(100%_-_2rem)] max-w-screen-2xl h-auto -translate-y-1/2 -translate-x-1/2 right-0 left-1/2 bg-white/[100] opacity-0 invisible z-[999] rounded-sm ease-in duration-200 }
    .modal.is-active { @apply opacity-100 visible top-1/2 }
    .modal .modal-inner iframe { @apply p-4 }
    .modal-overlay { @apply fixed inset-0 bg-black/[0] invisible z-[997] ease-in-out duration-200 }
    .modal-overlay.is-active { @apply bg-black/[0.75] visible }
    /* main */
    .main { @apply relative  border-t-[1px] lg:border-t-0 border-b-[1px] border-zinc-200 }
    /* section */
    .section { @apply relative pt-16 pb-16 }
    /* toggle switch dark mode */
    .toggle-switch input:checked ~ .dot { @apply translate-x-full }
    /* links */
    .link-hover { @apply ease-in-out duration-300 text-gray-500 hover:text-orange-600 font-bold }
    /* footer */
    .footer { @apply relative grid pt-16 pb-16 bg-no-repeat bg-cover min-h-[26rem] h-full bg-white font-montserrat }
    .footer .socials { @apply grid grid-cols-socials gap-4 justify-center }
    .footer .socials li { @apply w-14 h-14 }
    .footer .socials .socials-link { @apply grid place-items-center h-full border-2 border-white rounded-full shadow-md }
    .footer .socials .socials-link .icon { @apply bg-cover bg-no-repeat bg-center w-8 h-8 }
    /* footer contact */
    .footer .footer-top { @apply grid grid-rows-contact }
    .footer .footer-top .contact { @apply relative grid h-full w-full gap-4 md:grid-flow-col md:auto-cols-max md:justify-center }
    /* footer copyright - bottom */
    .footer .footer-bottom { @apply text-gray-400 }
    /* footer select all first div */
    .footer > .container > div { @apply pt-4 pb-4 }

    /* title */
    .title { @apply relative tracking-widest font-semibold text-lg md:text-xl lg:text-2xl text-zinc-500 pl-2 pr-2 }
    .title::after { @apply absolute -bottom-4 content-[''] left-0 right-0 h-[3px] bg-white w-2/6 mx-auto }
    /* paragraph */
    .paragraph { @apply text-lg lg:text-xl mb-10 font-montserrat !leading-9 tracking-wider font-light }
    .paragraph-border { @apply pb-4 border-b-[1px] border-black/[0.05] }
    /* list */
    .list { @apply pt-8 pb-10 border-l-[4px] border-orange-400 }
    .list-item { @apply mb-2 leading-8 text-lg font-thin }
    /* link */
    .link { @apply underline decoration-1 font-semibold text-sm md:text-base text-blue-600 hover:text-blue-500 ease-in duration-200 }
    /* btn */
    .btn { @apply outline-none }
    /* figure avatar */
    .figure-avatar { @apply relative w-[284px] h-[284px] mx-auto border-[3px] border-white rounded-full overflow-hidden shadow-md }
    .figure-avatar .figure-image { @apply absolute inset-0 }
    .figure-avatar .figure-caption { @apply absolute -bottom-20 left-0 right-0 text-center p-4 backdrop-blur-md bg-black/[.3] ease-in-out duration-300 delay-100 border-t-[3px] border-transparent }
    .figure-avatar:hover > .figure-caption { @apply bottom-0 }
    /* Menu item list */
    .menu-item { @apply grid gap-4 }
    .menu-item .item { @apply inline-block  }
    .menu-item .item .icon { @apply inline-block w-5 h-5 mr-2 pt-1 }
    .paragraph .icon { @apply inline-block w-5 h-5 mr-2 ml-2 pt-2 }

    @media screen(max-lg) {
      .nav { @apply fixed right-12 bottom-4 top-4 opacity-0 bg-white w-[calc(100%-2rem)] max-w-sm -z-10 ease-in-out duration-300 delay-100 rounded-md overflow-hidden }
      .nav.is-active { @apply right-4 opacity-100 z-[601] }

      .nav ul li:not(:last-child) { @apply border-b-[1px] }
      .nav ul li a { @apply p-0 h-[2.5rem] }
      .nav-trigger { @apply grid }

      .nav-overlay { @apply grid fixed inset-0 bg-black/[0] -z-[10] ease-in-out duration-300 delay-100 }
      .nav-overlay.is-active { @apply bg-black/[0.5] z-[599] }      
    }

    @media screen(lg) {
      .nav { @apply sticky top-0 left-0 right-0 z-50 bg-white/[0.95] backdrop-blur-md }      
      .nav ul { @apply justify-center grid-cols-nav }
      .nav ul li:not(:last-child) a::after { @apply content-[''] clear-both absolute top-1/2 -translate-y-1/2 right-0 bottom-0 w-[1px] h-[16px] bg-gray-300 }
      .figcaption { @apply absolute top-1/2 left-1/2 -translate-x-1/2 translate-y-1/4 z-20 w-full max-w-lg opacity-0 rounded-sm }
      .swiper-slide-active .figcaption { @apply ease-in-out duration-300 delay-200 opacity-100 -translate-y-1/2 }
    }      
}


.title {
  font-family: 'Lobster Two', cursive;
  font-weight: 400;
  letter-spacing: 0.1em;
  width: 80%;
  margin: auto;
}

/* swiper carousel */
/* transition for buttons next and prev */
.swiper .swiper-button-next,
.swiper .swiper-button-prev { @apply ease-in-out duration-300 delay-100 opacity-100 }

@media screen(max-lg) {
  .swiper .swiper-button-next,
  .swiper .swiper-button-prev { @apply hidden }
}

@media screen(lg) {

  /* hidden buttons with translate */
  .swiper .swiper-button-next { @apply translate-x-20 }
  .swiper .swiper-button-prev { @apply -translate-x-20 }


  /* buttons show on hover */
  .swiper:hover .swiper-button-next,
  .swiper:hover .swiper-button-prev { @apply translate-y-0 translate-x-0 }
}

/* swiper carousel arrow next - prev */
.swiper-button-next,
.swiper-button-prev { @apply text-white }

/* swiper carousel dots */
.swiper-pagination-bullets .swiper-pagination-bullet { @apply bg-white }


.animation-both {
  animation-fill-mode: both;
}
